import React, { useState, useEffect, useContext } from "react";
import Axios from "utility/Axios";
import configURL from 'config/config';
import useForm from 'react-hook-form';
import { Spinner, Breadcrumb } from "react-bootstrap";
import { toast } from "react-toastify";
import AppContext from 'store/AppContext';
/**Text Editor Import Start */
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import DropdownPlaceholder from './DropdownPlaceholder';
import { CHANNEL_PLACEHOLDERS, COMMUNICATION_TOOLBAR_CONFIG } from "constants/constants"
import "assets/scss/_htmleditor.scss"
/**Text Editor Import End */

const SaveCommunicationTemplate = ({ history, location, match }) => {
    const { EziLoader } = useContext(AppContext)
    const [templateData, setTemplateData] = useState({ name: '', subject: '', message: '' });
    const [editorMessage, setEditorMessage] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(""))));
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, errors, setValue } = useForm();
    const [lastFocusInput, setLastFocusInput] = useState(null);
    const templateType = location.state.template_type || "email";
    const templateId = match.params.template_id || null;
    const categoryId = location.state.category_id || null;
    const categoryName = location.state.category_name || null;

    /**
     * Save Template Data.
     * 
     * @param {object} data 
     */
    const handleFormSubmit = data => {
        let apiUrl = (templateId && templateId !== "") ? configURL.update_communication_template : configURL.save_communication_template
        let formData = new FormData()
        let templateMsg = (templateType === 'email') ? draftToHtml(convertToRaw(editorMessage.getCurrentContent())) : data.message
        formData.append('name', data.name || '')
        formData.append('subject', data.subject || '')
        formData.append('message', templateMsg || '')
        if(categoryId){
            formData.append('category_id', categoryId)
        }
        formData.append('template_type', templateType)
        if (templateId && templateId !== "") {
            formData.append('id', templateId)
        }
        setLoading(true)
        Axios.post(apiUrl, formData).then(res => {
            setLoading(false)
            if (res.data.success === true) {
                toast.success(res.data.message || 'Template saved successfully')
                history.push('/communication-templates')
            } else {
                toast.warn(res.data.message || 'Something went wrong here. Please try again')
            }
        }).catch(err => {
            setLoading(false)
            console.log(err);
        })
    };

    /**
     * Handle Variable insert on input
     */
    const insertVariable = ({ target }) => {
        if (!lastFocusInput || lastFocusInput === '') {
            return;
        }
        try {
            let { value: textToInsert = '' } = target;
            if (lastFocusInput === 'subject') {
                let { value: inputValue = '', selectionStart: cursorPosition = 0 } = document.getElementById('template_subject');
                let textBefore = inputValue.substring(0, cursorPosition);
                let textAfter = inputValue.substring(cursorPosition, inputValue.length);
                let finalText = textBefore + textToInsert + textAfter;
                setValue('subject', finalText)
            }
            if (lastFocusInput === 'sms') {
                let { value: inputValue = '', selectionStart: cursorPosition = 0 } = document.getElementById('template_message');
                let textBefore = inputValue.substring(0, cursorPosition);
                let textAfter = inputValue.substring(cursorPosition, inputValue.length);
                let finalText = textBefore + textToInsert + textAfter;
                setValue('message', finalText)
            }
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        if (templateId !== null) {
            let formData = new FormData()
            formData.append('category_id', categoryId)
            formData.append('template_type', templateType)
            formData.append('id', templateId)
            EziLoader.show()
            Axios.post(configURL.view_template_detail, formData).then(res => {
                EziLoader.hide()
                if (res.data.success === true) {
                    let { name = "", subject = '', body: message = '' } = res.data.results
                    setTemplateData({ name, subject, message })
                    if (templateType === 'email')
                        setEditorMessage(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(message))))

                } else {
                    toast.warn(res.data.message || 'Something went wrong here. Please try again')
                }
            }).catch(err => {
                EziLoader.hide()
                console.log(err);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
            <section className="Page-SaveCommunicationTemplate">
                <div className="breadcrumb_ezi template-add-beadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => history.push("/communication-templates")}>
                            Communication Templates
                        </Breadcrumb.Item>
                        <Breadcrumb.Item onClick={history.goBack}>{categoryName}</Breadcrumb.Item>
                        <Breadcrumb.Item>{templateId ? 'Edit Template' : "Create New"}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="survey-category-wrap">
                        <h1 className="category_label">
                            {!templateId && `Create new ${templateType || 'email'} template`}
                            {templateId && `Edit ${templateType || 'email'} template`}
                        </h1>
                        <div className="category-field-control">
                            <input type="text" name="name" defaultValue={templateData.name || ''} ref={register({ required: true })} placeholder="Template Name" className="create-category-input" onFocus={() => setLastFocusInput(null)} />
                            {errors.name && <span className="error-message">Please enter template name</span>}
                        </div>
                        <div className="category-field-control">
                            <div className="placeholder-btn-wrap">
                                {
                                    CHANNEL_PLACEHOLDERS.map((item) =>
                                        <div key={item.id} className="placeholder-btn-inner">
                                            <button type="button" className="insert-placeholder-btn" value={item.value} onClick={insertVariable}>{item.label}</button>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="category-field-control">
                            <input type="text" name="subject" defaultValue={templateData.subject || ''} ref={register({ required: true })} placeholder="Template Subject" className="create-category-input" onFocus={() => setLastFocusInput('subject')} id='template_subject' />
                            {errors.subject && <span className="error-message">Please enter template subject</span>}
                        </div>
                        <div className="category-field-control">
                            {templateType === 'sms' && <textarea defaultValue={templateData.message || ''} rows="4" name="message" ref={register} className="create-category-input" placeholder="Template Message..." onFocus={() => setLastFocusInput('sms')} id='template_message'></textarea>}
                            {templateType === 'email' && <Editor
                                wrapperClassName="ezi-editor-wrapper"
                                editorClassName="ezi-editor-inner"
                                toolbarClassName="ezi-editor-toolbar"
                                editorState={editorMessage}
                                placeholder='Template Message...'
                                toolbar={COMMUNICATION_TOOLBAR_CONFIG}
                                onFocus={() => setLastFocusInput(null)}
                                onEditorStateChange={setEditorMessage}
                                toolbarCustomButtons={[<DropdownPlaceholder />]}
                            />}
                        </div>
                        <div className="category_add_btn_wrap">
                            <button type="submit" className="btn-ripple category_add_btn" disabled={loading}>
                                {(templateId && templateId !== "") && "Update Template"}
                                {(!templateId) && "Create Template"}
                                {loading && <Spinner animation="border" variant="light" size="sm" />}
                            </button>
                        </div>
                    </div>
                </form>
            </section>
        </React.Fragment>
    )
}

export default SaveCommunicationTemplate;