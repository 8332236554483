import React from 'react';
import { Switch } from "react-router-dom";
import RootRouter from "./router";
import PrivateRoute from './PrivateRoute';
import { connect } from "react-redux";

/**
 * Lazy Load page
 */
const PageNotFound = React.lazy(() => import('components/PageNotFound'));

const InnerRoutes = ({ appState }) => (

    < Switch >
        {
            RootRouter.map((route, index) => {
                if (appState.is_main_user === route.main_user) {
                    return <PrivateRoute key={index} path={route.path} exact component={route.component} />
                }
                if (route.component && route.skip_access === true) {
                    return <PrivateRoute key={index} path={route.path} exact component={route.component} />
                }
                return null
            })
        }
        < PrivateRoute component={PageNotFound} />
    </Switch >
)
const mapStateToProps = state => {
    return {
        appState: state.app.appState,
    }
}
export default connect(mapStateToProps)(InnerRoutes);