import React from 'react';

const InnerLoader = () => {
    return (
        <div className="search-loader-position">
            <div className="serach-loader-wrapper_c">
                <div className="search-loader-inner_c"></div>
            </div>
        </div>
    )
}
export default InnerLoader