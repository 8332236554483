
import { createStore, combineReducers, compose } from "redux";
import appReducer from "store/reducers/appReducer";
import templateReducer from "store/reducers/templateReducer";

const rootReducer = combineReducers({
    app: appReducer,
    template: templateReducer,
})
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers());

export default store;