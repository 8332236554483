import React, { useContext } from 'react';
import '../assets/scss/navbar.scss';
import { Dropdown } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import AppContext from 'store/AppContext';
import { connect } from "react-redux";
import * as AppActions from "store/actions";

const Navbar = ({ navbarPara, appState, logOutUser, vendorInfo }) => {

    const history = useHistory();
    const { languageObj } = useContext(AppContext);

    /**
     * Logout User
     */
    const handleLogout = () => {
        localStorage.removeItem('isLogin');
        localStorage.removeItem('appState');
        logOutUser()
        history.push("/");
    }

    return (
        <React.Fragment>
            <header className="header">
                <div className="menu-wrap">
                    <div className="logo-wrap">
                        <div className="humberger">
                            <div className="humberger-inner" onClick={navbarPara.sidebarCollapse}>
                                <img alt="" src={require(`../assets/images/sidebar/header-humburger.svg`)} className="header-humburger-icon" />
                            </div>
                        </div>
                        <div className="logo">
                            <img alt="" src={vendorInfo.logo || require(`../assets/images/logo.png`)} className="logo-img" />
                        </div>
                    </div>
                    <div className="notification-info">
                        <div className="notification">
                            <span className="noti-icon"></span>
                        </div>
                        <div className="languageDropdown">
                            <Dropdown alignRight>
                                <Dropdown.Toggle id="dropdown-basic">
                                    <div className="flag-avtar-wrap">
                                        <label className="country-flag-label">{languageObj.curLang || "En"}</label>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {languageObj.languages.length > 0 && languageObj.languages.map((item, index) => (
                                        <Dropdown.Item key={item.id}>
                                            <div className="flag-img-wrap" onClick={() => languageObj.changeLang(item.lang_code)}>
                                                <label className="country-flag-label">{item.lang_code}</label>
                                            </div>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="user-info">
                            <div className="user-detail">
                                <label>{(appState.user && appState.user.name) || "User"}</label>
                                <span>{(appState.current_partner_account && appState.current_partner_account.name) || "Unknown"}</span>
                            </div>
                            <div className="userInfoDropdown">
                                <Dropdown alignRight>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        <div className="user-avtar">
                                            <img className="avtar" alt="" src={(appState && appState.user && appState.user.profile_pic_url) ? appState.user.profile_pic_url : require(`../assets/images/user.png`)} />
                                            <span className="test"></span>
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => history.push("/user-profile")}>{languageObj.translate('Profile.1')}</Dropdown.Item>
                                        {/* <Dropdown.Item>{languageObj.translate('ChangeEmail.1')}</Dropdown.Item>
                                        <Dropdown.Item>{languageObj.translate('ChangePassword.1')}</Dropdown.Item> */}
                                        <Dropdown.Item onClick={handleLogout}><span className="user-indication">{languageObj.translate('Not.1')} {(appState.user && appState.user.name) || "User"}?</span> {languageObj.translate('SignOut.1')}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <div className="responsive-menu-wrap">
                        <span className={navbarPara.mobMenu ? "active" : ""}></span>
                        <button type="button" className="menu-button" onClick={navbarPara.mobileMenu}><span className="menu-icon"></span></button>
                    </div>
                </div>
            </header>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        appState: state.app.appState,
        vendorInfo: state.app.vendorInfo
    }
}
const mapDispatchToProps = dispatch => {
    return {
        logOutUser: () => dispatch(AppActions.logOutUser())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);