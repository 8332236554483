import React, { useState, useRef, useContext, useEffect } from "react";
import './UserProfile.scss';
import ViewUserProfile from "./ViewUserProfile";
import useForm from 'react-hook-form';
import Axios from "utility/Axios";
import configURL from "config/config";
import { toast } from "react-toastify";
import AppContext from 'store/AppContext';
import * as AppActions from "store/actions";
import { connect } from "react-redux";
import DatePicker from 'react-date-picker';
import { AiTwotoneMail, AiOutlineQrcode } from "react-icons/ai";
import { BsFillChatLeftFill, BsWhatsapp } from "react-icons/bs";


const UserProfile = ({ updateUser }) => {
    const { EziLoader } = useContext(AppContext)
    let localStore = localStorage.getItem("appState");
    const userDetail = localStore ? JSON.parse(localStore).user : {};
    const { register, handleSubmit, errors, getValues, setValue } = useForm()
    const [editProfile, setEditProfile] = useState(false);
    const [viewUserData, setViewUserData] = useState(userDetail);
    const [userData, setUserData] = useState(userDetail);
    const [themeColor, setThemeColor] = useState(JSON.parse(localStore).current_partner_account);
    const [editPwd, setEditPwd] = useState(false);
    const imageRef = useRef(null)
    const [jobDetails, SetJobDetails] = useState();
    const [openSurveyCount, SetOpenSurveyCount] = useState();
    const [symbolsArr] = useState(["e", "E", "-", "."]);
    const appStore = JSON.parse(localStorage.getItem("appState"));
    const date  = appStore?.current_app_detail || {created_at:new Date()};
    let timezone = appStore.user.timezone;
	// var todayDate = new Date();
	// let time = todayDate.getHours() + ":" + todayDate.getMinutes() + ":" + todayDate.getSeconds();
	let utcTime = new Date().getTimezoneOffset("en-US", { timeZone: timezone });
	let timeZoneString = utcTime.toString();
	let UtcDiff = time_convert(timeZoneString.substring(1), timeZoneString[0]);
    const [countDate, setCountDate] = useState({
		start_date: new Date(new Date(date.created_at)) || new Date(),
		end_date: new Date(),
		utc: UtcDiff
	});
    //convert timezone into utc time difference
	function time_convert(num, sign) {
		var hours = Math.floor(num / 60);
		var minutes = num % 60;
		return sign + hours + ":" + minutes;
	}
    /**
     * Save User Data
     * 
     * @param {Object} data 
     */
    const handleSaveUser = (data) => {
        EziLoader.show()
        let formData = new FormData();
        if (data.first_name) {
            formData.append("first_name", data.first_name)
        }
        if (data.last_name) {
            formData.append("last_name", data.last_name)
        }
        if (data.cell_number) {
            formData.append("cell_number", data.cell_number)
        }
        if (data.email) {
            formData.append("email", data.email)
        }

        if (data.current_password) {
            formData.append("current_password", data.current_password)
        }
        if (data.new_password) {
            formData.append("new_password", data.new_password)
        }
        if (data.profile_pic && data.profile_pic.length > 0) {
            formData.append("profile_pic_url", data.profile_pic[0])
        }
        if (data.theme_color && data.theme_color.length > 0) {
            formData.append("theme_color", data.theme_color)
        }

        Axios.post(configURL.update_company_user, formData).then(res => {
            EziLoader.hide()
            if (res.data.success === true) {
                toast.success(res.data.message || "Profile Updated")
                let profile_pic = res.data.data.profile_pic_url || null;
                let theme_color = res.data.theme_color || null;
                let localStore = JSON.parse(localStorage.getItem("appState"));
                setUserData({ ...userData, profile_pic_url: profile_pic })
                setThemeColor({ ...themeColor, theme_color: theme_color })
                setViewUserData({ ...userData, profile_pic_url: profile_pic })
                setEditProfile(false)
                setEditPwd(false)
                const newUserData = { ...localStore, user: { ...localStore.user, ...userData, profile_pic_url: profile_pic }, current_partner_account:{ ...localStore.current_partner_account, ...themeColor } }
                localStorage.setItem("appState", JSON.stringify(newUserData))
                updateUser()
            } else {
                toast.warn(res.data.message.toString() || "Something went wrong.")
            }
        }).catch(err => {
            EziLoader.hide()
        })
    }

    const handleChangeInput = ({ target }) => {
        setValue(target.name, target.value.trim(), { shouldValidate: true })
        if (target.name === 'theme_color') {
            setThemeColor({
                ...themeColor,
                [target.name]: target.value.trim()
            })
            return;
        }
        if (target.name === 'first_name') {
            setUserData({
                ...userData,
                name: target.value.trim(),
                first_name: target.value.trim()
            })
            return;
        }
        setUserData({
            ...userData,
            [target.name]: target.value.trim(),
        })

    }
    const previewFile = () => {
        const file = document.querySelector('input[type=file]').files[0];
        const reader = new FileReader();
        reader.addEventListener("load", function () {
            imageRef.current.src = reader.result
        }, false);
        reader.readAsDataURL(file);
    }

    const communicationDetails = () => {
        EziLoader.show()
        let formData = new FormData();
        formData.append("from", new Date(countDate.start_date.getTime() - (countDate.start_date.getTimezoneOffset() * 60000)).toJSON())
        formData.append("to", new Date(countDate.end_date.getTime() - (countDate.end_date.getTimezoneOffset() * 60000)).toJSON())
        Axios.post(configURL.communication_job_details, formData).then(res => {
            if (res.data.success === true) {
                SetJobDetails(res.data?.results?.communications)
                SetOpenSurveyCount(res.data?.results?.responses)
                EziLoader.hide()
            } else {
                EziLoader.hide()
                toast.warn(res.data.message.toString() || "Something went wrong.")
            }
        }).catch(err => {
            EziLoader.hide()
        })
    }

    useEffect(communicationDetails,[]);

    /* eslint-disable */
    return (
        <React.Fragment>
            <section className="Page-UserProfile" >
                <form onSubmit={handleSubmit(handleSaveUser)}>
                    <div className="profile-wrap">
                        <h1>Profile</h1>
                        {!editProfile && <ViewUserProfile editProfile={() => setEditProfile(!editProfile)} userDetail={viewUserData} />}
                        {editProfile &&
                            <div className="user-info-card">
                                <div className="user-info-header">
                                    <div className="user-info-img-wrap">
                                        <div className="ul-logo-wrap">
                                            <img ref={imageRef} alt="" src={userDetail.profile_pic_url || require(`../../assets/images/partner-default-logo.svg`)} className="ul-default-logo" />
                                            <div className="ul-logo-upload-position">
                                                <div className="ul-logo-upload-wrapper">
                                                    <span className="ul-logo-upload-btn"></span>
                                                    <input type="file" name="profile_pic" ref={register} className="ul-logo-upload-input" onChange={previewFile} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="user-text-wrap">
                                            <span className="title">{userData.first_name || 'NA'} {userData.last_name || 'NA'}</span>
                                            <span className="subtitle">{userDetail.assigned_roles && [...new Set(userDetail.assigned_roles)].join(', ') || "NA"}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-info-body">
                                    <div className="user-info-col">
                                        <div className="lbl-control">
                                            <label>First Name</label>
                                            <div className="val-control">
                                                <input className="fld-value-input" name="first_name" ref={
                                                    register({
                                                        required: true
                                                    })
                                                } defaultValue={userData.first_name} onChange={handleChangeInput} />
                                                {errors.first_name && errors.first_name.type === 'required' && <span className={`error-message`}>Please Enter First Name</span>}
                                            </div>
                                        </div>
                                        <div className="lbl-control">
                                            <label>Last Name</label>
                                            <div className="val-control">
                                                <input className="fld-value-input" name="last_name" ref={
                                                    register({
                                                        required: true
                                                    })
                                                } defaultValue={userData.last_name} onChange={handleChangeInput} />
                                                {errors.last_name && errors.last_name.type === 'required' && <span className={`error-message`}>Please Enter Last Name</span>}
                                            </div>
                                        </div>
                                        <div className="lbl-control">
                                            <label>Primary Email</label>
                                            <div className="val-control">
                                                <input className="fld-value-input" name="email" ref={register({
                                                    required: true, pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/i
                                                })} defaultValue={userData.email} onChange={handleChangeInput} />
                                                {errors.email && errors.email.type === 'required' && <span className={`error-message`}>Please Enter Email Id.</span>}
                                                {errors.email && errors.email.type === "pattern" && <span className={`error-message`}>Please Enter Valid Email</span>}
                                            </div>
                                        </div>
                                        <div className="lbl-control">
                                            <label>Mobile Number</label>
                                            <div className="val-control">
                                                <input type="number" className="fld-value-input" name="cell_number" ref={register({
                                                    pattern: /^((\+)?(\d{2}[-]))?(\d{10}){1}?$/
                                                })} defaultValue={userData.cell_number} onChange={handleChangeInput} 
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}/>
                                                {errors.cell_number && errors.cell_number.type === "pattern" && <span className={`error-message`}>Please Enter Valid Cell Number</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {appStore?.is_main_user !== undefined && appStore?.is_main_user === true && <div className="security-wrap">
                        <h1>Company theme Color</h1>
                        <div className="security-card">
                            <div className="to-to-from mb-0" >
                                <div className="startend-date-wrap">
                                    <label className="date-start theme-color">Theme Color </label>
                                    <input className="fld-value-input themecolor" name="theme_color" ref={register} defaultValue={themeColor?.theme_color || "#CE2E6C"} onChange={handleChangeInput} type="color" />
                                </div>
                                <button type="submit"  className="ezi-pink-btn btn-ripple go">Save</button>
                            </div>
                        </div>
                    </div> } 
                    <div className="security-wrap">
                        
                        <h1>Manage Usage</h1>
                            <div className="security-card">
                                <div className="to-to-from" >
                                    <div className="startend-date-wrap">
                                        <label className="date-start">Start Date </label>
                                        <DatePicker 
                                        className="sweet-datepicker-custom" 
                                        selected={countDate.start_date || new Date()}
										value={countDate.start_date || new Date()}
                                        minDate={new Date(date.created_at) || new Date()}
										maxDate={countDate.end_date || new Date()}
                                        onChange ={(d) => setCountDate({ ...countDate, start_date: d })}
                                        />
                                    </div>
                                    <div className="startend-date-wrap end-wrap">
                                        <label className="date-start">End Date </label>
                                        <DatePicker
                                        selected={countDate.end_date || new Date()}
                                        value={countDate.end_date || new Date()}
                                        minDate={countDate.start_date || new Date()}
                                        maxDate={new Date()} 
                                        onChange={(d) => setCountDate({ ...countDate, end_date: d })} 
                                        className="sweet-datepicker-custom" 
                                        />
                                    </div>
                                    <button type="button" onClick={communicationDetails} className="ezi-pink-btn btn-ripple go">Go</button>
                                </div>
                                <div className="totalCount">
                                    {/* {jobDetails && console.log(jobDetails)} */}
                                {jobDetails && jobDetails.map((item) => 
                                    <div className="st_card">
                                        <div className="st_card-icon-wrap">
                                            { item?.category === "Email" && <span className="st_card-icon template_ic"><AiTwotoneMail /></span>}
                                            { item?.category === "WhatsApp" && <span className="st_card-icon template_ic"><BsWhatsapp /></span>}
                                            { item?.category === "Sms" && <span className="st_card-icon template_ic"><BsFillChatLeftFill /></span>}
                                        </div>
                                        <div className="st_card-text-wrap">
                                            <span className="st_card-text">{item.category}</span>
                                            <span className="st_count">{item.total}</span>
                                        </div>
                                    </div>
                                )} 
                                {openSurveyCount && openSurveyCount.map((item) => 
                                    <div class="st_card">
                                        <div class="st_card-icon-wrap">
                                            { item.category === 'openSurvey' && <span class="st_card-icon template_ic"><AiOutlineQrcode /></span>}
                                        </div>
                                        <div class="st_card-text-wrap">
                                            <span class="st_card-text">{item.category && "QR"}</span>
                                            <span class="st_count">{item.total}</span>
                                        </div>
                                    </div>
                                )}
                                </div>
                            </div>
                    </div>
                    <div className="security-wrap">
                        <h1>Security</h1>
                        {!editPwd &&
                            <div className="security-card">
                                <div className="change-pass-wrap">
                                    <div className="pass-fld-wrap">
                                        <label>Password</label>
                                        <div className="pass-val-control">
                                            <span className="pass-val">*****</span>
                                        </div>
                                    </div>
                                    <button type="button" className="ezi-btn btn-ripple change-pas" onClick={() => setEditPwd(!editPwd)}>Change Password</button>
                                </div>
                            </div>}
                        {editPwd &&
                            <div className="security-card">
                                <div className="pass-fld-wrap">
                                    <label>Current Password</label>
                                    <div className="pass-val-control">
                                        <input type="password" className="fld-pass-input" name="current_password" ref={register({ required: true })} />
                                        {errors.current_password && errors.current_password.type === 'required' && <span className={`error-message`}>You must specify a Old Password</span>}
                                    </div>
                                </div>
                                <div className="pass-fld-wrap">
                                    <label>New Password</label>
                                    <div className="pass-val-control">
                                        <input type="password" className="fld-pass-input" name="new_password" ref={register({ required: true, minLength: 8 })} />
                                        {errors.new_password && errors.new_password.type === 'required' && <span className={`error-message`}>You must specify a new password</span>}
                                        {errors.new_password && errors.new_password.type === 'minLength' && <span className={`error-message`}>New Password should be atleast 8 character long</span>}
                                    </div>
                                </div>
                                <div className="pass-fld-wrap">
                                    <label>Confirm New Password</label>
                                    <div className="pass-val-control">
                                        <input type="text" className="fld-pass-input" name="password_match" ref={register({
                                            required: true,
                                            validate: value => (value === getValues()["new_password"])
                                        })} />
                                        {errors.password_match && errors.password_match.type === 'required' && <span className={`error-message`}>You must specify a confirmation password</span>}
                                        {errors.password_match && errors.password_match.type === 'validate' && <span className={`error-message`}>The passwords do not match</span>}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {(editProfile || editPwd) && <div className="profile-save-wrap">
                        <button className="ezi-btn btn-ripple  ezi-pink-btn profile-save" type="submit">Save</button>
                        <button className="ezi-btn profile-cancel" onClick={() => {
                            setEditProfile(false)
                            setEditPwd(false)
                        }}>Cancel</button>
                    </div>}
                </form>
            </section>
        </React.Fragment>
    )
}
/* eslint-enable */
const mapDispatchToProps = dispatch => {
    return {
        updateUser: () => dispatch(AppActions.updateUser())
    }
}
export default connect(null, mapDispatchToProps)(UserProfile);