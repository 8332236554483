/** App actions type*/
export const LOGOUT = "LOGOUT"
export const LOGIN = "LOGIN"
export const UPDATE_USER = "UPDATE_USER"
export const INIT_VENDOR = "INIT_VENDOR"

/*------------Template Actions---------------------*/
export const SET_TEMPLATE_INITIAL = "SET_TEMPLATE_INITIAL"
export const SET_TEMPLATE_LAUNCHABLE = "SET_TEMPLATE_LAUNCHABLE"
export const RESET_TEMPLATE_STATE = "RESET_TEMPLATE_STATE"
export const SET_TEMPLATE_LOCALES = "SET_TEMPLATE_LOCALES"
export const SET_TEMPLATE_LOCALE = "SET_TEMPLATE_LOCALE"