import React, { useCallback, useContext, useState, useEffect } from 'react';
import '../Companies/BenchmarkBulkUpload.scss';
import Dropzone from "hooks/Dropzone";
import EziLoader from 'components/EziLoader';
import AppContext from 'store/AppContext';
import Axios from 'utility/Axios';
import configURL from 'config/config';
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import { Breadcrumb } from 'react-bootstrap';

var file;
const BenchmarkBulkUpload = ({ history }) => {
    const { languageObj = {} } = useContext(AppContext)
    const onDrop = useCallback(acceptedFiles => { file = acceptedFiles[0]; }, []);
    const [loading, setLoading] = useState(false);
    const [upload, setUpload] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const urlParams = useParams();
    const benchMarkData = {
        benchmark_name: history.location.state.BMname,
        accountID: history.location.state.accountId
    }
    //benchmark Template download
    const getBenchmarkTemplate = () => {
        try {
            let formData = new FormData()
            formData.append("benchmark_id", urlParams.benchmark_id);
            Axios.post(configURL.benchmark_bulk_upload_template, formData, { responseType: 'blob' }).then(res => {
                if (res?.headers["content-type"] !== "application/json") {
                    const href = URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', urlParams.benchmark_id + '-' + new Date().toString() + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                }
                else {
                    toast.warn(languageObj.translate('report_section.file_not_found'))
                }
            })
        } catch (error) {
            console.log(error);
            toast.warn(languageObj.translate('report_section.something_went_wrong'))
        }
    }

    //uploading the benchmark file
    useEffect(() => {
        if (upload) {
            setLoading(true);
            const formData = new FormData();
            formData.append("file", file);
            formData.append("benchmark_id", urlParams?.benchmark_id)
            Axios.post(configURL.benchmark_bulk_upload, formData).then(response => {
                setLoading(false);
                if (response.data.success !== undefined && response.data.success) {
                    setUploaded(true);
                    setUpload(true);
                } else {
                    setUpload(false);
                    toast.error(response.data.message || languageObj.translate('report_section.something_went_wrong'));
                }
            }).catch(err => {
                setLoading(false);
                console.log(err)
            })
        }
    }, [upload]);
    return (
        <React.Fragment>
            <section className="Page-BulkUpload">
                <div className="breadcrumb_ezi people-directory-breadcrumb">
                    <div className="breadcrumb_ezi head-bor">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => history.push("/companies")}>Companies</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => history.push(`/benchmark-listing/${benchMarkData.accountID}`)}>Benchmarks</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => history.goBack()}>Edit Benchmarks</Breadcrumb.Item>
                            <Breadcrumb.Item >Bulk Upload</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="column-header">
                        <h1 className="page-heading">{(benchMarkData?.benchmark_name) || "Benchmark"}</h1>
                        <button className="btn-ripple download-template ezi-pink-btn" onClick={getBenchmarkTemplate}>
                            {languageObj.translate('DownloadTemplate.1')}
                        </button>
                    </div>
                </div>
                {!upload && <>
                    <div className="dropzone-wrap">
                        <Dropzone onDrop={onDrop} accept={".xlsx, .xls, .csv"} />
                    </div>
                    <div className="dropzone-btn">
                        <input type="button" className="btn-ripple upload-btn ezi-pink-btn" onClick={() => { setUpload(true) }} value={languageObj.translate('UploadSelectedFile.1')} />
                    </div>
                    <span className="dropzone-supported-file">Please do not upload file size greater than 50MB.</span>
                </>}
                {loading && <EziLoader />}
                {uploaded &&
                    <div className="upload-benchmark-list completed">
                        <div className="success-div">
                            <img src={require(`../../assets/images/survey/checked.png`)} alt="check" width="80px" />
                            <div className="uploded-done my-2">{languageObj.translate("BenchmarkUploadedSuccessfully.1")}</div>
                        </div>
                    </div>
                }
            </section>
        </React.Fragment>
    )
}
export default BenchmarkBulkUpload;