import React, { useState, useEffect, useRef } from "react";
import './CommunicationTemplates.scss';
import { Breadcrumb, Tab, Nav } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import Axios from "utility/Axios";
import configURL from 'config/config';
import EziLoader from "components/EziLoader";
import { confirmAlert } from 'react-confirm-alert';
import SearchFilter from "components/SearchFilter";
import { toast } from "react-toastify";

const CommunicationTemplates = ({ history }) => {
    const [defaultCategory, setDefaultCategory] = useState({});
    const [categories, setCategories] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [pagination, setPagination] = useState({});
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('email');
    const perPage = useRef(10);

    const addNewTemplate = () => {
        history.push('/communication-templates/save', {
            category_name: defaultCategory.name,
            category_id: defaultCategory.id,
            template_type: activeTab,
        })
    }

    const editTemplate = (id = null) => {
        history.push(`/communication-templates/save/${id}`, {
            category_name: defaultCategory.name,
            category_id: defaultCategory.id,
            template_type: activeTab,
        })
    }

    const AddNewFormatter = (column, _) => {
        return (
            <div className="add-communication-template-btn-wrap">
                <button type="button" className={`btn-ripple ezi-pink-btn add-communication-template-btn`} onClick={addNewTemplate}>{column.text} <span className="add_ic"></span></button>
            </div>
        );
    }

    const actionFormatter = (_, row) => {
        row.category_id = defaultCategory.id || null
        return (
            <div className="communication-template-editDelete-wrap">
                <button type="button" className={`communication-template-edit`} onClick={() => editTemplate(row.id)}>Edit</button>
                <button type="button" className={`communication-template-delete`} onClick={() => deleteEntry(row.id)}>Delete</button>
            </div>
        );
    }

    const columns = [
        { dataField: "name", text: "Template Name" },
        { dataField: "subject", text: "Subject" },
        { dataField: 'add', text: 'Add New', headerFormatter: AddNewFormatter, formatter: actionFormatter },
    ];

    const changeCategory = (categoryObj) => {
        setDefaultCategory(categoryObj);
        getCommunicationTemplates(categoryObj);
    }

    const getCommunicationTemplates = (categoryObj, page = 1, type = activeTab) => {
        setLoading(true)
        let formData = new FormData();
        formData.append("category_id", categoryObj.id);
        formData.append("page", page);
        formData.append("template_type", type);
        formData.append("per_page", perPage.current);
        Axios.post(configURL.get_communication_templates, formData).then(res => {
            setLoading(false)
            if (res.data.success !== undefined && res.data.success) {
                setPagination(res.data.pagination);
                setTemplates(res.data.result)
            } else {
                setTemplates([]);
            }
        }).catch(err => {
            console.log(err);
            setLoading(false)
        })
    }

    const RemotePagination = ({ data, page = 1, sizePerPage, onTableChange, totalSize }) => (
        <BootstrapTable
            remote
            keyField="id"
            data={data} columns={columns}
            noDataIndication="Table is Empty"
            pagination={paginationFactory({ page, sizePerPage, totalSize, hideSizePerPage: true, })}
            onTableChange={onTableChange}
            rowClasses="communication-template-datatable-row"
        />
    );

    const handleAllEntriesChange = (type, { page }) => {
        if (type === 'pagination') {
            getCommunicationTemplates(defaultCategory, page);
        }
    }

    const deleteEntry = (rowId) => {
        confirmAlert({
            title: 'Delete Template',
            message: `Are you sure ? You want to delete this communication template.`,
            buttons: [
                {
                    label: 'Okay',
                    onClick: () => {
                        if (rowId !== null) {
                            let formData = new FormData();
                            formData.append("id", rowId);
                            formData.append("template_type", activeTab);
                            Axios.post(configURL.delete_communication_template, formData).then(resposnse => {
                                if (resposnse?.data?.success === true) {
                                    toast.success(resposnse?.data?.message || 'Template Deleted.')
                                    getCommunicationTemplates(defaultCategory);
                                } else {
                                    toast.warn(resposnse?.data?.message || 'Something went wrong. Please try again')
                                }
                            });
                        }
                    }
                },
                { label: 'Cancel' }
            ]
        });
    }

    const getInitialCatgory = () => {
        setLoading(true)
        let formData = new FormData();
        formData.append("type", 'template')
        Axios.post(configURL.partner_categoryDs, formData).then(res => {
            setLoading(false)
            if (res.data.result && res.data.result.length > 0) {
                let categoriesData = []
                res.data.result.forEach(item => {
                    categoriesData.push({ id: item.id, name: item.category_name, description: item.description });
                });
                setCategories(categoriesData);
                let categoryObj = categoriesData[0];
                setDefaultCategory(categoryObj);
                getCommunicationTemplates(categoryObj, 1);
            }
        }).catch(err => {
            console.log(err);
            setLoading(false)
        })
    }

    useEffect(getInitialCatgory, []);

    return (
        <React.Fragment>
            <section className="Page-CommunicationTemplates" >
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item >Communication Templates</Breadcrumb.Item>
                        <Breadcrumb.Item >{activeTab}</Breadcrumb.Item>
                    </Breadcrumb>
                    <SearchFilter handleSelect={changeCategory} data={categories} defaultSelected={defaultCategory.name} />
                    <span className="communication-template-subtitle"> {(defaultCategory.description !== undefined) ? defaultCategory.description : ''} </span>
                </div>
                <div className="communication-template-content">
                    <div className="ezi-sweet-tab">
                        <Tab.Container defaultActiveKey={activeTab} onSelect={tab => {
                            setActiveTab(tab)
                            getCommunicationTemplates(defaultCategory, 1, tab)
                        }}>
                            <div className="sweet-tab-left-header">
                                <Nav variant="pills" >
                                    <Nav.Item>
                                        <Nav.Link eventKey="email" >Email</Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="sms">Sms</Nav.Link>
                                    </Nav.Item> */}
                                </Nav>
                            </div>
                            <Tab.Content>
                                <RemotePagination
                                    data={templates}
                                    page={pagination.current_page}
                                    sizePerPage={perPage.current}
                                    totalSize={pagination.total}
                                    onTableChange={handleAllEntriesChange}
                                />
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </section>
            {loading && <EziLoader />}
        </React.Fragment>
    )
}

export default CommunicationTemplates;