import React, { useState, useEffect, useRef, useContext } from "react";
import Axios from "utility/Axios";
import configURL from 'config/config';
import { getFirstWord } from 'utility/helper'
import './AssignAdvancedReport.scss';
import { toast } from "react-toastify";
import SweetSearch from "components/SweetSearch";
import Pagination from "react-js-pagination";
import AppContext from 'store/AppContext';
import { useParams } from "react-router-dom";

const AssignAdvancedReport = () => {
    const { EziLoader } = useContext(AppContext);
    const [categoryTemplates, setCategoryTemplates] = useState([]);
    const [pagination, setPagination] = useState({});
    const [filterType] = useState("total");
    const perPage = 10;
    const [searchLoading, setSearchLoading] = useState(false);
    const inputSearch = useRef(null);
    var searchTimer = null;
    const urlParams = useParams();

    /**
     * Get Perticular template types.
     * 
     * @param {string} type template types
     * @param {number} page Page number
     */
    const getCategorySurvey = (page = 1, type = "total") => {
        let searchVal = inputSearch.current.value;
        let formData = new FormData();
        formData.append("account_id", urlParams.account_id);
        formData.append("per_page", perPage);
        formData.append("page", page);
        formData.append("search", searchVal);
        Axios.post(configURL.company_wise_survey_list, formData).then(response => {
            if (response.data.success !== undefined && response.data.success === true) {
                setCategoryTemplates(response.data?.results?.data);
                setPagination(response.data?.results);
            } else {
                setCategoryTemplates([]);
                setPagination({});
                toast.warn(response.data.message);
            }
            EziLoader.hide()
            setSearchLoading(false)
        }).catch(err => {
            EziLoader.hide()
            console.log(err.toString())
        })
    }

    /**
     * Get Perticular template types.
     * 
     * @param {string} type template types
     * @param {number} page Page number
     */
    const HandleAssignAdvancedReport = (data, condition) => {
        EziLoader.show();
        let formData = new FormData();
        formData.append("survey_id", data?.id);
        formData.append("account_id", urlParams.account_id);
        formData.append("advanced_report_access", condition);
        Axios.post(configURL.update_advanced_report_access, formData).then(response => {
            if (response.data.success !== undefined && response.data.success === true) {
                getCategorySurvey();
                toast.success(response.data.message.toString())
            } else {
                toast.warn(response.data.message);
            }
            EziLoader.hide()
            setSearchLoading(false)
        }).catch(err => {
            EziLoader.hide()
            console.log(err.toString())
        })
    }

    /**
     * Filter Data based on search.
     * 
     * @param {string} type Input value
     */
    const handleFilterSearch = ({ target }) => {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setSearchLoading(true);
            getCategorySurvey(1, filterType);
        }, 800);
    }

    /**
     * Handle Pagination
     * 
     * @param {string} type Filter Type
     */
    const handlePagination = (page = 1) => {
        EziLoader.show()
        getCategorySurvey(page, filterType);
    }

    useEffect(() => {
        EziLoader.show()
        getCategorySurvey()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <section className="Page-Advanced-Report">
                <div className="cat-search-count-wrap justify-content-end">
                    <SweetSearch loading={searchLoading} change={handleFilterSearch} ref={inputSearch} />
                </div>
                <div className="category-dashboard-table">
                    <div className="category-dashboard-table-row category-table-heading">
                        <div className="category-dashboard-table-cell"> Survey Name </div>
                        <div className="category-dashboard-table-cell"> Enable Advanced-Report </div>
                    </div>
                    {
                        categoryTemplates?.length > 0 ? categoryTemplates?.map((item, index) => {
                            return (
                                <div key={index} className="category-dashboard-table-row">
                                   
                                    <div className="category-dashboard-table-cell" data-title="Template Name">
                                        <div className={`category-table-template-wrap `}>
                                            <div className="category-table-template-text-wrap">
                                                <span className="category-table-template-name">{item?.name}</span>
                                                <span className="category-table-create">Created on: {getFirstWord(item?.created_at)}   |   Report Type Name: {getFirstWord(item?.report_type_name)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="category-dashboard-table-cell table-status" data-title="Assign Anonymity">
                                        <label className="switch-toggle" onClick={() => (HandleAssignAdvancedReport(item, !item?.advanced_report_access))}>
                                            <span className={`toggle-slider round ${(item?.advanced_report_access === true) ? 'active' : ''}`}></span>
                                        </label>
                                    </div>
                                </div>
                            )
                        }) : <h6 className="data-not-found" style={{ textAlign: "center" }}>No result Found</h6>
                    }
                </div>
                <div className="pagination-plugin-wrap category-pagination-formatter">
                    <Pagination
                        activePage={pagination?.current_page}
                        itemsCountPerPage={perPage}
                        totalItemsCount={pagination?.total || 0}
                        onChange={handlePagination}
                        hideDisabled={true}
                    />
                </div>
            </section>
        </React.Fragment>
    )
}

export default AssignAdvancedReport;