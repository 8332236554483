import React, { Fragment } from "react";
import { Spinner } from "react-bootstrap";

const SweetSearch = React.forwardRef((props, ref) => (
    <Fragment>
          <div className="sweet-search-table-header">
            <div className="ezi-sweet-search-wrap">
                <input type="text" ref = {ref} className="sweet-search-input" placeholder="Search" onChange={props.change}/>
                {props.loading  &&  <Spinner animation="border" className="sweet-search-spinner" /> }
            </div>
          </div>
    </Fragment>
));
export default SweetSearch;
