import * as actionsTypes from "store/actions/actionTypes";

const appStateData = localStorage.getItem("appState") ? JSON.parse(localStorage.getItem("appState")) : {}

const initalState = {
    appState: appStateData,
	vendorInfo: {
        name: null,
        logo: null,
        webaddress: null
    }
}

const appReducer = (state = initalState, action) => {
    switch (action.type) {
        case actionsTypes.LOGIN:
            return {
                ...state,
                appState: localStorage.getItem("appState") ? JSON.parse(localStorage.getItem("appState")) : {}
            }
        case actionsTypes.LOGOUT:
            return {
                ...state,
                appState: {}
            }
        case actionsTypes.UPDATE_USER:
            return {
                ...state,
                appState: localStorage.getItem("appState") ? JSON.parse(localStorage.getItem("appState")) : {}
            }
		case actionsTypes.INIT_VENDOR:
            return {
                ...state,
                vendorInfo: {
                    name: action.payload.name,
                    logo: action.payload.logo,
					webaddress: action.payload.webaddress
                }
            }
        default:
            return state
    }
}

export default appReducer
