import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Container from './Container';
import './utility/i18next.js';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import AppLoading from 'components/AppLoading';
import { Provider } from "react-redux";
import store from 'store/store';

ReactDOM.render(
    <Suspense fallback={<AppLoading message = 'Loading'/>}>
        <Provider store={store}><Container /></Provider>
    </Suspense>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
