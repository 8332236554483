import React from 'react';
import '../assets/scss/Skeleton.scss';

const Skeleton = (props) => {
    return(
        <React.Fragment>
                {
                    [...Array(props.value)].map((e, i) => 
                    <div className="survey_card_skeleton" key={`skeleton${i}`}>
                        <div className="skeleton-line skeleton-heading"></div>
                        <div className="skeleton-line skeleton-45"></div>
                        <div className="skeleton-line"></div>
                        <div className="skeleton-line skeleton-85"></div>
                        <div className="skeleton-line skeleton-60"></div>
                    </div>
                    )
                }
  
        </React.Fragment>
    )


}

export default Skeleton;