import React, { Fragment } from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const DataTable = (props) => {
    const {
        data,
        page = 1,
        sizePerPage,
        onTableChange,
        keyField,
        columns,
        totalSize,
        seletedIds,
        handleCheck,
        hideSelectAll,
        hideSelect,
        handleSelectAll
    } = props;
    return (
        <Fragment>
            <BootstrapTable
                remote
                keyField={keyField}
                data={data}
                noDataIndication="No users found"
                rowClasses="ezi-datatable-row"
                columns={columns}
                pagination={paginationFactory({ page, sizePerPage, totalSize, hideSizePerPage: true })}
                onTableChange={onTableChange}
                selectRow={{ mode: 'checkbox', hideSelectColumn: hideSelect, onSelect: handleCheck, selected: seletedIds, hideSelectAll: hideSelectAll, onSelectAll: handleSelectAll }}
            />
        </Fragment>
    )
}
export default DataTable;