import * as actionsTypes from "store/actions/actionTypes";

const initalState = {
    templateName: "",
    categoryName: "",
    templateStatus: "",
    publishStatus: "",
    canSave: false,
    templateLocale: 'English',
    templateLocales: []
}

const templateReducer = (state = initalState, action) => {
    switch (action.type) {
        case actionsTypes.SET_TEMPLATE_INITIAL:
            return {
                ...state,
                templateName: action.payload.templateName,
                categoryName: action.payload.categoryName,
                templateStatus: action.payload.templateStatus,
                publishStatus: action.payload.publishStatus,
            }
        case actionsTypes.SET_TEMPLATE_LAUNCHABLE:
            return {
                ...state,
                canSave: action.payload.canSave
            }
        case actionsTypes.SET_TEMPLATE_LOCALES:
            return {
                ...state,
                templateLocales: action.payload.locales
            }
        case actionsTypes.SET_TEMPLATE_LOCALE:
            return {
                ...state,
                templateLocale: action.payload.language
            }
        case actionsTypes.RESET_TEMPLATE_STATE:
            return initalState
        default:
            return state
    }
}

export default templateReducer
