import React, { useContext } from 'react';
import '../assets/scss/sidebar.scss';
import { NavLink } from "react-router-dom";
import AppContext from 'store/AppContext';

const Sidebar = ({ sidebarPara }) => {
    const { languageObj = {}, appState = {} } = useContext(AppContext);
    return (
        <React.Fragment>
            <nav id="sidebar" className={`sidebar ${sidebarPara.sidebar ? "active" : ""}`}>
            <div className='sidebar-wrapper'>
                <div className="sidebar-navigation">
                    <button type="button" className="humburger-button" onClick={sidebarPara.overlaySidebar}>
                        <img alt="" src={require(`../assets/images/sidebar/humburger.svg`)} className="humburger-logo" />
                    </button>
                    <div className="navigation-text">
                        {/* {languageObj.translate('Navigation.1')} */}
                        <p className='m-0'>{(appState.current_partner_account && appState.current_partner_account.name) || "Unknown"}</p>
                    </div>
                </div>
                <ul className="sidebar-list">
                    <li className="sidebar-item">
                        <NavLink activeClassName='link-active' to="/dashboard" onClick={sidebarPara.overlaySidebar} className="side-icon dashboard">
                            {languageObj.translate('Dashboard.1')}
                        </NavLink>
                    </li>
                    <li className="sidebar-item">
                        <NavLink activeClassName='link-active' to="/companies" onClick={sidebarPara.overlaySidebar} className="side-icon themes">
                            companies
                        </NavLink>
                    </li>
                    <li className="sidebar-item">
                        <NavLink activeClassName='link-active' to="/template-dashboard" onClick={sidebarPara.overlaySidebar} className="side-icon templates">
                            {languageObj.translate('Templates.1')}
                        </NavLink>
                    </li>
                    <li className="sidebar-item">
                        <NavLink activeClassName='link-active' to="/themes" onClick={sidebarPara.overlaySidebar} className="side-icon themes">
                            {languageObj.translate('Themes.1')}
                        </NavLink>
                    </li>
                    <li className="sidebar-item">
                        <NavLink activeClassName='link-active' to="/question-bank" onClick={sidebarPara.overlaySidebar} className="side-icon question-bank-template">
                            Question Bank
                        </NavLink>
                    </li>
                    <li className="sidebar-item">
                        <NavLink activeClassName='link-active' to="/communication-templates" onClick={sidebarPara.overlaySidebar} className="side-icon communication-templates">
                            Email Templates
                        </NavLink>
                    </li>
                    {appState.is_main_user !== undefined && appState.is_main_user === true &&
                        <li className="sidebar-item">
                            <NavLink activeClassName='link-active' to="/partner-user" onClick={sidebarPara.overlaySidebar} className="side-icon user">
                                User Management
                            </NavLink>
                        </li>
                    }
                    <li className="sidebar-item">
                        {/* <NavLink activeClassName='link-active' to="/support" onClick={sidebarPara.overlaySidebar} className="side-icon support">
                            {languageObj.translate('Support.1')}
                        </NavLink> */}
                        <a className="side-icon support" href="https://support.qaizenx.com" target="_blank" rel="noopener noreferrer">{languageObj.translate('Support.1')}</a>
                    </li>
                </ul>
                {/* <div className="copyright-text">
                    © {new Date().getFullYear()} Qaizenx ™. <br />All Rights Reserved.
                </div> */}
                </div>
            </nav>
        </React.Fragment>
    )
}
export default Sidebar;

