import React, { useState } from "react";
import { Modal, Spinner } from 'react-bootstrap';
import useForm from 'react-hook-form';
import Axios from "utility/Axios";
import configURL from 'config/config';
import { toast } from 'react-toastify';

function EditCategoryModel({ visible: show = false, onHide, categoryData = {}, updateList }) {
    const { register, handleSubmit, errors } = useForm();
    const [saving, setSaving] = useState(false)

    const onSubmit = data => {
        setSaving(true)
        let formData = new FormData()
        formData.append("category_name", data.name)
        formData.append("description", data.description)
        formData.append("id", categoryData.id)

        Axios.post(configURL.edit_category, formData).then(res => {
            setSaving(false)
            if (res.data.success === true) {
                onHide()
				updateList()
                toast.success(res.data.message || "Category updated")
            } else {
                toast.warn(res.data.message || "Something went wrong.")
            }
        }).catch(err => {
            setSaving(false)
            console.log(err)
            toast.warn("Something went wrong.")
        })
    };

    return (

        <Modal show={show} onHide={onHide} size="md" aria-labelledby="contained-modal-title-vcenter" centered className="theme-modal-wrapper" >
            <Modal.Header className="ezi-modal-header">
                <Modal.Title id="contained-modal-title-vcenter" className="theme-modal-title ezi-modal-header-title" >
                    <span className="theme-modal-title-text">Edit category</span>
                    <span className="ezi-modal-close" onClick={onHide}></span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)} className="add-theme-form">
                    <div className="theme-field-wrapper">
                        <div className="theme-field-50">
                            <div className="theme-field">
                                <label>Name</label>
                                <input type="text" className="theme-field-control" name="name" ref={register({ required: true })} placeholder="Enter Category Name" defaultValue={categoryData && categoryData.category_name} />
                                {errors.name && <span className="theme-error_cu">* Name is required.</span>}
                            </div>
                        </div>
                        <div className="theme-field">
                            <div className="theme-field">
                                <label>Description</label>
                                <textarea rows="4" cols="50" className="theme-field-control" name="description" ref={register({ required: true })} defaultValue={categoryData && categoryData.description} ></textarea>
                                {errors.description && <span className="theme-error_cu">* Description is required.</span>}
                            </div>
                        </div>
                    </div>
                    <div className="theme-modal-footer">
                        <button type="button" className="close-theme-btn" onClick={onHide}>Close</button>
                        <button type="submit" className="btn-ripple ezi-pink-btn add-theme-btn" disabled={saving}>
                            Save {saving && <Spinner animation="border" size="sm" />}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}


export default EditCategoryModel;