import React, { Fragment, useState, useEffect } from 'react';
import App from "./App"
import { connect } from "react-redux";
import * as AppActions from "store/actions";
import Http from "axios";
import PageNotFound from 'components/PageNotFound';
import AppLoading from 'components/AppLoading';
import configURL from 'config/config';

const Container = ({ dispatchVenderInfo }) => {
    const [loading, setLoading] = useState(true)
    const [isVerified, setIsVerified] = useState(false)

    useEffect(() => {
        let vendorName;
        if (window.location.host || window.location.hostname) {
            vendorName = window.location.hostname
            if (!vendorName) vendorName = window.location.host;
            let formData = new FormData()
            formData.append('type', 'partner')
            formData.append('name', vendorName)
            Http.post(configURL.verify_vendor, formData).then(response => {
                if (response.data.success) {
                    setLoading(false)
                    setIsVerified(true)
                    dispatchVenderInfo({ name: response.data.vendorName, logo: response.data.logoUrl, webaddress: vendorName })
                } else {
                    setLoading(false)
                    setIsVerified(false)
                }
            }).catch(err => {
                console.log(err);
                setLoading(false);
                setIsVerified(false);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            {(!loading && !isVerified) && <PageNotFound message='Page not found' />}
            {isVerified && <App name="Durgesh Company" />}
            {loading && <AppLoading message='Loading' />}
        </Fragment>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchVenderInfo: (info) => dispatch(AppActions.initVendor(info))
    }
}
export default connect(null, mapDispatchToProps)(Container);