import * as Survey from "survey-knockout";
import './Widget.scss';
import $ from "jquery";
import { uniqueGenerator } from "utility/helper";

var widget = {
    name: "nps_rating",
    title: "NPS",
    iconName: "",
    widgetIsLoaded: function () {
        return true;
    },
    isFit: function (question) {
        return question.getType() === 'nps_rating';
    },
    activatedByChanged: function () {
        Survey.JsonObject.metaData.addClass("nps_rating", [], null, "radiogroup");
    },
    isDefaultRender: false,
    htmlTemplate: `
        <div class='nps-widget-wrapper'>
            <fieldset>
                <div class='nps-widget-inner'> 
                    <span class='nps-label-left'>Most Unlikely</span>
                    <div class='nps-radio-wrapper'>
                        <input class='nps-radio' type='radio' name='npsradio' value='0' />
                        <span class='nps-number' value='0'>0</span>
                    </div>
                    <div class='nps-radio-wrapper'> 
                        <input class='nps-radio' type='radio' name='npsradio' value='1' /> 
                        <span class='nps-number' value='1'>1</span> 
                    </div>
                    <div class='nps-radio-wrapper'> 
                        <input class='nps-radio' type='radio' name='npsradio' value='2' />
                        <span class='nps-number' value='2'>2</span> 
                    </div>
                    <div class='nps-radio-wrapper'> 
                        <input class='nps-radio' type='radio' name='npsradio' value='3' /> 
                        <span class='nps-number' value='3'>3</span> 
                    </div>
                    <div class='nps-radio-wrapper'> 
                        <input class='nps-radio' type='radio' name='npsradio' value='4' /> 
                        <span class='nps-number' value='4'>4</span> 
                    </div>
                    <div class='nps-radio-wrapper'> 
                        <input class='nps-radio' type='radio' name='npsradio' value='5' />
                        <span class='nps-number' value='5'>5</span>
                    </div>
                    <div class='nps-radio-wrapper'>
                        <input class='nps-radio' type='radio' name='npsradio' value='6' />
                        <span class='nps-number' value='6'>6</span>
                    </div>
                    <div class='nps-radio-wrapper'>
                        <input class='nps-radio' type='radio' name='npsradio' value='7' />
                        <span class='nps-number' value='7'>7</span>
                    </div>
                    <div class='nps-radio-wrapper'>
                        <input class='nps-radio' type='radio' name='npsradio' value='8' />
                        <span class='nps-number' value='8'>8</span>
                    </div>
                    <div class='nps-radio-wrapper'>
                        <input class='nps-radio' type='radio' name='npsradio' value='9' />
                        <span class='nps-number' value='9'>9</span>
                    </div>
                    <div class='nps-radio-wrapper'>
                        <input class='nps-radio' type='radio' name='npsradio' value='10' />
                        <span class='nps-number' value='10'>10</span>
                    </div>
                    <span class='nps-label-right'>Most Likely</span>
                </div>
            </fieldset>
        </div>
    `,
    afterRender: function (question, el) {
        var elements = el.getElementsByTagName("input");
        var text = el.getElementsByTagName("fieldset")[0];
        text.inputType = question.inputType;
        console.log(question.value);
        var matches = text.querySelectorAll("input.nps-radio");
        var elName = "npsradio_" + uniqueGenerator();
        for (let index = 0; index < matches.length; index++) {
            matches[index].name = elName
        }
        if (question.value) {
            $('input[name="' + elName + '"][value="' + question.value + '"]').prop('checked', true);
        }
        text.onchange = function () {
            for (var i = 0, l = elements.length; i < l; i++) {
                if (elements[i].checked) {
                    question.value = elements[i].value;
                }
            }
        }
        var onValueChangedCallback = function () {
            for (var i = 0, l = elements.length; i < l; i++) {
                if (elements[i].checked) {
                    question.value = elements[i].value;
                }
            }
        }
        var onReadOnlyChangedCallback = function () {
            if (question.isReadOnly) {
                text.setAttribute('disabled', 'disabled');
            } else {
                text.removeAttribute("disabled");
            }
        };
        question.readOnlyChangedCallback = onReadOnlyChangedCallback;
        question.valueChangedCallback = onValueChangedCallback;
        onValueChangedCallback();
        onReadOnlyChangedCallback();

    },
    willUnmount: function (question, el) {
    }
}
Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, "customtype");
export default widget;
