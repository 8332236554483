import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";

const Dropzone = ({ onDrop, accept, sampleLink }) => {

	const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({ onDrop, accept });
	const [fileName, setFileName] = useState(null);
	const getClassName = (className, isActive) => {
		if (!isActive) return className;
		return `${className} ${className}-active`;
	};

	useEffect(() => {
		if (acceptedFiles.length > 0) {
			let files = acceptedFiles.map(item => item.name)
			setFileName(files.join(', '))
		}
	}, [acceptedFiles]);

	return (
		<section className="container">
			{sampleLink && <a className="dynamic-participant-download" href={sampleLink} title="Download Sample Data" target='_blank'></a>}
			<div className="dropzone-area" {...getRootProps()}>
				<input className="dropzone-input" {...getInputProps()} />
				<div className="text-center ">
					{isDragActive ? (
						<p className="dropzone-content">Release to drop the files here</p>
					) : (
							<div className="dropzone-content">
								<span className="dropzone-icon"></span>
								<span className={`drag-text ${fileName ? 'active' : ''}`}>{fileName ? fileName : "Drag & Drop File"}</span>
							</div>
						)}
				</div>
				<div className={getClassName("dropzone", isDragActive)} {...getRootProps()}></div>
			</div>
		</section>
	);
};

export default Dropzone;