/* eslint-disable array-callback-return */
import React, { useState, useEffect, useContext, useRef } from "react";
import './AddCompany.scss';
import { Breadcrumb, Tab, Nav, Container, Row, Col } from 'react-bootstrap';
import useForm from 'react-hook-form';
import configURL from 'config/config';
import { TIME_ZONES, COMPANY_WEBADDRESS, INDUSTRY_LIST, COUNTRIES, END_DATE, STATUS_OPTION } from 'constants/constants';
import Axios from "utility/Axios";
import { toast } from 'react-toastify';
import Select from 'react-select';
import { outlineRemove } from 'utility/helper';
import AppContext from 'store/AppContext';
import { connect } from "react-redux";
import DatePicker from 'react-date-picker';
import { AiTwotoneMail, AiOutlineQrcode } from "react-icons/ai";
import { BsFillChatLeftFill, BsWhatsapp } from "react-icons/bs";


const SaveCompany = ({ history, match, vendorInfo }) => {

    const { EziLoader } = useContext(AppContext)
    const [companyTab, setCompanyTab] = useState('general');
    const { register, handleSubmit, errors, reset, setValue } = useForm();
    const [submitClicked, setSubmitClicked] = useState(false);
    const [companyData, setCompanyData] = useState({});
    const [urlErr, setUrlErr] = useState(false)
    const [timeZone, setTimeZone] = useState({ label: "Asia/Kolkata", value: "Asia/Kolkata" });
    const [country, setCountry] = useState({ label: "India", value: "IN" });
    const [endDate, setEndDate] = useState({ "label": "1 Year", "value": "365" });
    const [credentialEmail, setCredentialEmail] = useState(false);
    const [ceoDashboard, setCeoDashboard] = useState(false);
    const [status, setStatus] = useState({ "label": "Active", "value": "true" });
    const [emailErr, setEmailErr] = useState();
    const [alreadyExist, setAlreadyExist] = useState(false);
    const [existName, setExistName] = useState("");
    const [existPhone, setExistPhone] = useState("");
    const [choosenFile, setChoosenFile] = useState(true);
    let searchTimer = null;
    const company_id = match.params.account_id
    const imageRef = useRef(null)
    // let domain = (vendorInfo.webaddress) ? vendorInfo.webaddress.split('.') : ""
    let domain = (vendorInfo.webaddress) ? vendorInfo.webaddress.split('ally') : ""
    let partnerDomain = (domain.length > 0) ? `${domain[1]}` : COMPANY_WEBADDRESS
    const [filterByDate, setFilterByDate] = useState({ start_date: new Date() });
    const [jobDetails, SetJobDetails] = useState();
    const [openSurveyCount, SetOpenSurveyCount] = useState();
    const [symbolsArr] = useState(["e", "E", "-", "."]);
    const [reportType, setReportType] = useState();
    const [reportTypes, setReportTypes] = useState([]);
    const localStore = localStorage.getItem("appState");
    const [cxWebAddress, setCxWebAddress] = useState(false);
    const [exWebAddress, setExWebAddress] = useState(false);
    const [assessmentWebAddress, setAssessmentWebAddress] = useState(false);
    const [appWebAddress, setAppWebAddress] = useState(false);
    const [cxAddress, setCxAddress] = useState('');
    const [exAddress, setExAddress] = useState('');
    const [assessmentAddress, setAssessmentAddress] = useState('');
    const [appAddress, setAppAddress] = useState('');
    // const appStore = JSON.parse(localStorage.getItem("appState"));
    // let timezone = appStore.user.timezone;
	// // var todayDate = new Date();
	// // let time = todayDate.getHours() + ":" + todayDate.getMinutes() + ":" + todayDate.getSeconds();
	// let utcTime = new Date().getTimezoneOffset("en-US", { timeZone: timezone });
	// let timeZoneString = utcTime.toString();
	// let UtcDiff = time_convert(timeZoneString.substring(1), timeZoneString[0]);
    const [countDate, setCountDate] = useState({
		start_date: new Date(),
		end_date: new Date()
	});
    //convert timezone into utc time difference
	// function time_convert(num, sign) {
	// 	var hours = Math.floor(num / 60);
	// 	var minutes = num % 60;
	// 	return sign + hours + ":" + minutes;
	// }

    /**
     * Save Company
     * @param {Object} data 
     */

    const submitFormData = (data) => {
        EziLoader.show()
        // let sendDataObj = {}
        const sendData = new FormData();
        let reports = [];
        reportType && reportType.map( (item) =>{
            reports.push( {report_type_id:item.value, report_type_name:item.label} )
        })
        // if (filterByDate.start_date.getTime() > newDate.getTime()) {
        //     confirmAlert({
        //         title: 'Date Error!',
        //         message: 'Start date could not be greater than End Date..',
        //         buttons: [{ label: 'I Understood' }]
        //     });
        //     EziLoader.hide()
        //     return;
        // }
        // if (newDate.getTime() < new Date().getTime() && new Date(new Date(newDate).toDateString()).getTime() !== new Date(new Date().toDateString()).getTime() ) {
        //     confirmAlert({
        //         title: 'Date Error!',
        //         message: "End date could not be less than Today's Date..",
        //         buttons: [{ label: 'I Understood' }]
        //     });
        //     EziLoader.hide()
        //     return;
        // }
        let startDate = filterByDate.start_date
        if(filterByDate.start_date.getHours() === 0){
            startDate = new Date(filterByDate.start_date.getTime() - (filterByDate.start_date.getTimezoneOffset() * 60000)).toJSON()
        }
        let logo = data.company_logo.length > 0 ? data.company_logo[0] : null
        let loginPageImage = data?.login_page_img?.length > 0 ? data?.login_page_img[0] : null
        if (logo) {
            sendData.append("company_logo", logo);
        }
        if (loginPageImage) {
            sendData.append("login_page_img", loginPageImage);
        }
        Object.keys(data).forEach(el => {
            if (!loginPageImage && el === "login_page_img") {
                companyData[el] = companyData.login_page_img
            } else {
                companyData[el] = data[el]  
            }
        })
        if (!data.appwebaddress && !data.cxwebaddress && !data.exwebaddress && !data.assessmentwebaddress) {
            toast.error("Please select atleast one web address.");
            EziLoader.hide();
            return;
        }
        if(companyData?.web_type !== "app" && companyData?.web_type !== "cx" && companyData?.web_type !== "ex" && companyData?.web_type !== "assessment" && !match.params.account_id){
            let domain = [data.appwebaddress && {'webaddress':`${data.appwebaddress}.app${partnerDomain}`,"web_type": "app", "enabled": appWebAddress}, data.cxwebaddress &&{'webaddress':`${data.cxwebaddress}.cx${partnerDomain}`, "web_type": "cx", "enabled": cxWebAddress}, data.exwebaddress && {'webaddress':`${data.exwebaddress}.ex${partnerDomain}`, "web_type": "ex", "enabled": exWebAddress}, data.assessmentwebaddress && {"webaddress":`${data.assessmentwebaddress}.assessment${partnerDomain}`, "web_type": "assessment", "enabled": assessmentWebAddress}]
            companyData.webaddresses = domain && domain.filter(item => item?.enabled && item);
        }
        companyData.timezone = timeZone?.value;
        companyData.country_code = country?.value;
        companyData.credentialEmail = credentialEmail?.value
        companyData.status = status?.value
        companyData.totalDays = endDate?.value
        companyData.start_date = filterByDate?.start_date.getFullYear() === 1970 ? null : startDate
        companyData.end_date = filterByDate?.start_date.getFullYear() === 1970 ? null : newDate
        if (reportType) {
            companyData.report_types = reports
        }
        // List of keys to remove
        const keysToRemove = [
            "appwebaddress",
            "cxwebaddress",
            "credentialEmail",
            "exwebaddress",
            "assessmentwebaddress"
          ];
        // Removing keys and their respective values
        keysToRemove.forEach(key => delete companyData[key]);
        sendData.append("insertdata", JSON.stringify({ 'data': companyData }));
        if (company_id) {
            sendData.append("account_id", company_id)
        }
        Axios.post(configURL.save_compnay_details, sendData).then(response => {
            EziLoader.hide()
            if (response.data.success === true) {
                toast.success(response.data.message || "Company Saved");
                history.push("/companies")
            } else {
                toast.warn(response.data.message || "Something went wrong");
            }
        }).catch(err => {
            console.log(err.toString())
            EziLoader.hide()
        })
    }

    /**
     * Check company URL
     * @param {Object} param0 
     */
    const handleCheckCompanyURL = ({ target }) => {
        let webType = (target.name === "appwebaddress" && 'app') || target.name === "cxwebaddress" && 'cx' || (target.name === "exwebaddress" && 'ex') || (target.name === "assessmentwebaddress" && 'assessment');
        let inputVal = target.value
        if(webType === 'cx')setCxAddress(inputVal);
        if(webType === 'ex')setExAddress(inputVal);
        if(webType === 'assessment')setAssessmentAddress(inputVal);
        if(webType === 'app')setAppAddress(inputVal);
        setValue('webaddress', inputVal.toLowerCase(), { shouldValidate: true })
        if (inputVal === "" || inputVal.length < 3) {
            setUrlErr(false)
            return;
        }
        clearTimeout(searchTimer)
        searchTimer = setTimeout(() => {
            let formData = new FormData()
            formData.append('url_name', `${inputVal}.${webType}${partnerDomain}`)
            formData.append('type', 'company')
            Axios.post(configURL.verify_vendor_url, formData).then(res => {
                if (res.data.success !== undefined && res.data.success) {
                    setUrlErr(false)
                } else {
                    setUrlErr(true)
                }
            })
        }, 1000);
    }



    /**
     * Check Email Id
     * @param {Object} param0 
     */
     const CheckEmailId = ({ target }) => {
        let inputVal = target.value
        setValue(target.name, target.value.trim(), { shouldValidate: true })
        // if (inputVal === "" || inputVal.length < 3) {
        //     // setUrlErr(false)
        //     return;
        // }
        clearTimeout(searchTimer)
        searchTimer = setTimeout(() => {
            let formData = new FormData()
            formData.append('email', inputVal)
            formData.append('type', 'company')
            Axios.post(configURL.check_emailId, formData).then(res => {
                if (res.data.success !== undefined && res.data.success === true) {

                    setEmailErr(res.data.success)
                    setExistName(res.data.results.first_name)
                    setExistPhone(res.data.results.cell_number)
                } 
                else {
                    setEmailErr(false)
                    setAlreadyExist(false)
                }
            })
        }, 1000);
    }

    const clickHandler = () => {
        setAlreadyExist(true)
    }

    useEffect(() => {
        if (alreadyExist) {
            setValue('first_name', existName);
            setValue('phone', existPhone);
            setValue('password', 'oldpassword');
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alreadyExist]);
    /**
     * Handle Form Error Tab
     */
    useEffect(() => {
        let errorsKey = Object.keys(errors)
        if (submitClicked && errorsKey.length > 0) {
            let general = ["companyname", "companywebsite", "companyaddress", "city", "zipcode", "webaddress"]
            let additional = ["first_name", "password", "phone", "email"]
            let errorTab = "";
            if (errorsKey.length > 0) {
                if (general.some(item => errorsKey.includes(item)) && errorTab === "") {
                    errorTab = "general";
                }
                if (additional.some(item => errorsKey.includes(item)) && errorTab === "") {
                    errorTab = "additional";
                }
                if (errorTab !== "") {
                    setCompanyTab(errorTab)
                }
            }
            setSubmitClicked(false)
        }
    }, [submitClicked, errors]);
    useEffect(() => {
        if (company_id) {
            EziLoader.show()
            let formData = new FormData()
            formData.append("account_id", company_id)
            Axios.post(configURL.get_compnay_details, formData).then(response => {
                EziLoader.hide()
                if (response.data.success) {
                    setCompanyData(response.data?.result)
                    setCeoDashboard(response.data?.result?.tplButton || false)
                    setCredentialEmail(response.data?.result?.send_email || false)
                    if (response.data.result?.status !== "true") {
                        setStatus({ label: "Inactive", value: "false" })
                    }
                    let AllReport = response.data.result?.report_types || []
                    if(AllReport?.length > 0){
                        let reports = []
                        AllReport.map((item) => {
                            reports.push(
                                { label: item?.report_type_name, value: item?.report_type_id }
                            )
                        })
                    setReportType(reports)
                }
                    if (response.data.result?.start_date) {
                        let startDate = new Date(response.data.result?.start_date)
                        let endDate = new Date()
                        setFilterByDate({ start_date: new Date(response.data.result.start_date) })
                        setCountDate({ start_date: startDate, end_date: endDate })
                        communicationDetails( startDate, endDate )
                    } else {
                        // console.log('enter');
                        let startDate = new Date(response.data.result.created_at)
                        let endDate = new Date()
                        setFilterByDate({ start_date: new Date(response.data.result.start_date) })
                        setCountDate({ start_date: startDate, end_date: endDate })
                        communicationDetails( startDate, endDate )
                    }
                    let index = END_DATE.findIndex(v => v.value === response.data.result.totalDays)
                    if (index !== -1) {
                        setEndDate(END_DATE[index])
                    }
                    if (response.data.result.timezone) {
                        setTimeZone({ label: response.data.result.timezone, value: response.data.result.timezone })
                    }
                    if (response.data.result.country_code) {
                        let country = COUNTRIES.find(element => element.value === response.data.result.country_code)
                        setCountry(country)
                    }
                }
            }).catch(err => {
                EziLoader.hide()
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Handle Form Reset
     */
    const clearForm = () => {
        reset({
            companyname: "",
            companywebsite: "",
            companyaddress: "",
            city: "",
            zipcode: "",
            first_name: "",
            password: "",
            phone: "",
            email: "",
            billingemailaddress: "",
            subscriptionplan: "",
            subscriptionstartdate: "",
            subscriptionenddate: "",
            webaddress: "",
            paymentmode: "",
            theme_color:"#CE2E6C",
            cxwebaddress: "",
            exwebaddress: "",
            assessmentwebaddress: "",
            appwebaddress: ""
        });
        setCxAddress('');
        setExAddress('');
        setAssessmentAddress('');
        setAppAddress('');
        setCxWebAddress(false);
        setExWebAddress(false);
        setAssessmentWebAddress(false);
        setAppWebAddress(false);
    }

    /**
     * Handle Image Upload
     * @param {Event}
     */
    const previewFile = () => {
        const file = document.querySelector('input[type=file]').files[0];
        const reader = new FileReader();
        reader.addEventListener("load", function () {
            imageRef.current.src = reader.result
        }, false);
        reader.readAsDataURL(file);
    }
    /* eslint-disable */
    let myFutureDate = new Date(filterByDate.start_date);
    var newDate = myFutureDate
    if (endDate.value !== "") {
        newDate = new Date(myFutureDate.setTime(myFutureDate.getTime() + endDate.value * 86400000));
    }

    const communicationDetails = ( startDate, endDate ) => {
        EziLoader.show()
        const startCountDate = new Date().getMinutes() === countDate?.start_date.getMinutes() && new Date().getHours() === countDate?.start_date?.getHours()  ? new Date(startDate?.getTime() - (startDate?.getTimezoneOffset() * 60000)).toJSON() : new Date(countDate?.start_date?.getTime() - (countDate?.start_date?.getTimezoneOffset() * 60000)).toJSON()
        let formData = new FormData();
        formData.append("from", startCountDate && startCountDate)
        formData.append("account_id", company_id)
        formData.append("to", new Date(countDate.end_date.getTime() - (countDate.end_date.getTimezoneOffset() * 60000)).toJSON())
        Axios.post(configURL.communication_job_details, formData).then(res => {
            if (res.data.success === true) {
                SetJobDetails(res.data?.results?.communications)
                SetOpenSurveyCount(res.data?.results?.responses)
                EziLoader.hide()
            } else {
                EziLoader.hide()
                toast.warn(res.data.message.toString() || "Something went wrong.")
            }
        }).catch(err => {
            EziLoader.hide()
        })
    }
    const getReportTypes =  () => {
        let formData = new FormData()
        formData.append("report_type", "partner")
        Axios.post(configURL.getReportTypes, formData).then(response =>{
        if (response.data.success) {
            let typesData = [];
            response.data.results.forEach(element => {
                typesData.push({
                    value: element.id,
                    label: element.name
                })
            });
            setReportTypes(typesData)
    }
    })
    }
    const showChoosenFile = () => {
        setChoosenFile(!choosenFile)
    }

    useEffect(getReportTypes,[]);

    return (
        <React.Fragment>
            <section className="Page-AddCompany" >
                <form onSubmit={handleSubmit(submitFormData)} className="company-form">
                    <div className="breadcrumb_ezi">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => history.push("/dashboard")}>Dashboard</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => history.push("/companies")}>Companies</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => history.push("/add-company")}>{company_id ? 'update ' : 'add'}</Breadcrumb.Item>
                        </Breadcrumb>
                        <div className="column-header">
                            <h1 className="page-heading">{company_id ? 'Update Company' : 'Add new company'}</h1>
                            <div className="column-header-btn">
                                <button type="button" className="btn-ripple clear_all" onClick={clearForm} >Clear all</button>
                                <button type="submit" className="btn-ripple add-new" onClickCapture={() => setSubmitClicked(true)} disabled={urlErr} title={urlErr ? 'Please check form errors' : 'Save'} >Save</button>
                            </div>
                        </div>
                    </div>
                    <div className="add-company-form-wrap">
                        <div className="company-info-header">
                            <div className="conpany-name-id-wrap">
                                <span className="title">{companyData.companyname || 'Company name'}</span>
                                {/* <span className="subtitle">Account ID</span> */}
                            </div>
                            <div className="company-add-logo-wrap">
                                <img ref={imageRef} alt="" src={companyData.company_logo || require(`../../assets/images/partner-default-logo.svg`)} className="company-default-logo" />
                                <div className="company-logo-upload-position">
                                    <div className="company-logo-upload-wrapper">
                                        <span className="company-logo-upload-btn"></span>
                                        <input type="file" name="company_logo" ref={register} className="company-logo-upload-input" onChange={previewFile} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tablist_ezi">
                            <Tab.Container activeKey={companyTab} onSelect={k => setCompanyTab(k)}>
                                <div className="tab-header-wrap">
                                    <div className="tab-left-header">
                                        <Nav variant="pills" >
                                            <Nav.Item>
                                                <Nav.Link eventKey="general">General</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="additional">Additional</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="Count">Manage Usage</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </div>
                                <Tab.Content>
                                    <Tab.Pane eventKey="general">
                                        <div className="add-company-field-wrapper">
                                            <label>
                                                <input type="text"
                                                    placeholder="Company Name"
                                                    name="companyname"
                                                    ref={register({ required: true,
                                                        pattern:/^$|^\S+.*/ })}
                                                    defaultValue={companyData.companyname || ""} />
                                                {errors.companyname && errors.companyname.type === 'required' &&
                                                    <span className="error_cu">Company Name is required</span>} 
                                                {errors.companyname && errors.companyname.type === 'pattern' &&
                                                    <span className="error_cu">Company Name is required</span>}
                                            </label>
                                            <label>
                                                <input type="text"
                                                    placeholder="Company Website"
                                                    name="companywebsite"
                                                    ref={register({ required: true })}
                                                    defaultValue={companyData.companywebsite || ""} />
                                                {errors.companywebsite && errors.companywebsite.type === 'required' &&
                                                    <span className="error_cu">Company Website Name is required</span>}
                                            </label>
                                            <label>
                                                <input type="text"
                                                    placeholder="Company Address"
                                                    name="companyaddress"
                                                    ref={register({ required: true })}
                                                    defaultValue={companyData.companyaddress || ""} />
                                                {errors.companyaddress && errors.companyaddress.type === 'required' &&
                                                    <span className="error_cu">Company Address is required</span>}
                                            </label>
                                            <label className="industry_list">
                                                <select name="industry" value={companyData.industry || ""} ref={register}
                                                    onChange={({ target }) => {
                                                        setCompanyData({ ...companyData, industry: target.value })
                                                    }}>
                                                    <option >Select Industry Vertical</option>
                                                    {
                                                        Object.keys(INDUSTRY_LIST).map((item, i) => <option key={i} value={item}>{INDUSTRY_LIST[item]}</option>)
                                                    }
                                                </select>
                                            </label>

                                            <label>
                                                <input type="text"
                                                    placeholder="City"
                                                    name="city"
                                                    ref={register({ required: true })}
                                                    defaultValue={companyData.city || ""} />
                                                {errors.city && errors.city.type === 'required' &&
                                                    <span className="error_cu">City is required</span>}
                                            </label>
                                            <label>
                                                <input type="text"
                                                    placeholder="Zipcode"
                                                    name="zipcode"
                                                    ref={register({ maxLength: 7, pattern: /^[0-9]*$/ })}
                                                    defaultValue={companyData.zipcode || ""} />
                                                {errors.zipcode && errors.zipcode.type === 'pattern' &&
                                                    <span className="error_cu">Zipcode is not valid</span>}
                                                {errors.zipcode && errors.zipcode.type === 'maxLength' &&
                                                    <span className="error_cu">Max zipcode length is 7</span>}
                                            </label>
                                            <label>
                                                <Select
                                                    ref={register}
                                                    className="timezone"
                                                    options={TIME_ZONES}
                                                    value={timeZone}
                                                    styles={outlineRemove}
                                                    placeholder="Time Zone"
                                                    onChange={(selectedOption) => {
                                                        setTimeZone(selectedOption)
                                                    }}
                                                />
                                            </label>
                                            <label>
                                                <Select
                                                    ref={register}
                                                    className="country"
                                                    options={COUNTRIES}
                                                    value={country}
                                                    styles={outlineRemove}
                                                    placeholder="Country"
                                                    onChange={(selectedOption) => {
                                                        setCountry(selectedOption)
                                                    }}
                                                />
                                            </label>
                                            <label className="save-company-lable">
                                                <div className="status-div">
                                                    <Select
                                                        ref={register}
                                                        options={STATUS_OPTION}
                                                        value={status}
                                                        styles={outlineRemove}
                                                        placeholder="Status"
                                                        onChange={(selectedOption) => {
                                                            setStatus(selectedOption)
                                                        }}
                                                    />
                                                </div>
                                            </label>
                                            <label className="save-company-lable">
                                                <div className="status-div">
                                                    <Select
                                                        ref={register}
                                                        isMulti
                                                        options={reportTypes}
                                                        value={reportType && reportType || ""}
                                                        styles={outlineRemove}
                                                        placeholder="Please select Report type"
                                                        onChange={(value) => {
                                                            setReportType(value)
                                                        }}
                                                    />
                                                </div>
                                            </label>
                                            {(companyData?.web_type === "cx" || window?.location?.pathname === '/add-company') && <label className="save-company-lable">
                                                <div className="status-div-web">
                                                    <div className="domain-adress-wrap">
                                                        <input type="checkbox" defaultValue={(cxWebAddress)} name="cxwebenable" onChange={(e) => {setCxWebAddress(e.target.checked), !(e.target.checked) && setCxAddress("")}} disabled={companyData?.web_type === "cx"} checked={cxWebAddress || companyData?.web_type === "cx"} className="check-addrs" />
                                                        <div className="url-text">
                                                            <input type="text"
                                                                placeholder="CX Web Address"
                                                                name="cxwebaddress"
                                                                ref={register({ required: false, pattern: /^[a-zA-Z'-]*$/ })}
                                                                value={cxAddress || (companyData?.webaddress) ? companyData?.webaddress?.split('.')[0] : ""}
                                                                defaultValue={(companyData.webaddress) ? companyData.webaddress.split('.')[0] : ""} 
                                                                onChange={handleCheckCompanyURL} 
                                                                disabled={(company_id || !cxWebAddress)} />
                                                            <span className="domain">{`.cx${partnerDomain}`}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {errors.cxwebaddress && errors.cxwebaddress.type === 'required' &&
                                                    <span className="error_cu">Web Address is required</span>}
                                                {errors.cxwebaddress && errors.cxwebaddress.type === 'pattern' &&
                                                    <span className="error_cu">Web Address is not valid</span>}
                                                {urlErr && <span className="error_cu">URL is not available</span>}
                                            </label>}
                                            {(companyData?.web_type === "ex" || window?.location?.pathname === '/add-company') && <label className="save-company-lable">
                                                <div className="status-div-web">
                                                    <div className="domain-adress-wrap">
                                                    <input type="checkbox" defaultValue={(exWebAddress)} name="exwebenable" onChange={(e) => {setExWebAddress(e.target.checked), !(e.target.checked) && setExAddress("")}} disabled={companyData?.web_type === "ex"} checked={exWebAddress || companyData?.web_type === "ex"} className="check-addrs"/>
                                                    <div className="url-text">
                                                        <input type="text"
                                                            placeholder="EX Web Address"
                                                            name="exwebaddress"
                                                            ref={register({ required: false, pattern: /^[a-zA-Z'-]*$/ })}
                                                            value={exAddress || (companyData?.webaddress) ? companyData?.webaddress?.split('.')[0] : ""}
                                                            defaultValue={(companyData.webaddress) ? companyData.webaddress.split('.')[0] : ""} 
                                                            onChange={handleCheckCompanyURL} 
                                                            disabled={(company_id || !exWebAddress)} />
                                                        <span className="domain">{`.ex${partnerDomain}`}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {errors.exwebaddress && errors.exwebaddress.type === 'required' &&
                                                    <span className="error_cu">Web Address is required</span>}
                                                {errors.exwebaddress && errors.exwebaddress.type === 'pattern' &&
                                                    <span className="error_cu">Web Address is not valid</span>}
                                                {urlErr && <span className="error_cu">URL is not available</span>}
                                            </label>}
                                            {(companyData?.web_type === "assessment" || window?.location?.pathname === '/add-company') && <label className="save-company-lable">
                                                <div className="status-div-web">
                                                    <div className="domain-adress-wrap">
                                                    <input type="checkbox" defaultValue={(assessmentWebAddress)} name="assessmentwebenable" onChange={(e) => {setAssessmentWebAddress(e.target.checked), !e.target.checked && setAssessmentAddress("")}} disabled={companyData?.web_type === "assessment"}  checked={assessmentWebAddress || companyData?.web_type === "assessment"} className="check-addrs" />
                                                    <div className="url-text">
                                                        <input type="text"
                                                            placeholder="Assessment Web Address"
                                                            name="assessmentwebaddress"
                                                            ref={register({ required: false, pattern: /^[a-zA-Z'-]*$/ })}
                                                            value={assessmentAddress || (companyData?.webaddress) ? companyData?.webaddress?.split('.')[0] : ""}
                                                            defaultValue={(companyData.webaddress) ? companyData.webaddress.split('.')[0] : ""} 
                                                            onChange={handleCheckCompanyURL} 
                                                            disabled={(company_id || !assessmentWebAddress)} />
                                                        <span className="domain">{`.assessment${partnerDomain}`}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {errors.assessmentwebaddress && errors.assessmentwebaddress.type === 'required' &&
                                                    <span className="error_cu">Web Address is required</span>}
                                                {errors.assessmentwebaddress && errors.assessmentwebaddress.type === 'pattern' &&
                                                    <span className="error_cu">Web Address is not valid</span>}
                                                {urlErr && <span className="error_cu">URL is not available</span>}
                                            </label>}
                                            {(companyData?.web_type === "app" || window?.location?.pathname === '/add-company' || (!companyData?.web_type && company_id)) && <label className="save-company-lable">
                                                <div className="status-div-web">
                                                    <div className="domain-adress-wrap">
                                                    <input type="checkbox" defaultValue={(appWebAddress)} value={(appWebAddress)} name="appwebenable" onChange={(e) => {setAppWebAddress(e.target.checked), !e.target.checked && setAppAddress("")}} disabled={companyData?.web_type === "app" || company_id} checked={appWebAddress || companyData?.web_type === "app" || company_id} className="check-addrs" />
                                                    <div className="url-text">
                                                        <input type="text"
                                                            placeholder="App Web Address"
                                                            name="appwebaddress"
                                                            ref={register({ required: false, pattern: /^[a-zA-Z'-]*$/ })}
                                                            value={appAddress || (companyData?.webaddress) ? companyData?.webaddress?.split('.')[0] : ""}
                                                            defaultValue={(companyData?.webaddress) ? companyData.webaddress.split('.')[0] : ""} 
                                                            onChange={handleCheckCompanyURL} 
                                                            disabled={company_id || !appWebAddress} />
                                                        <span className="domain">{`.app${partnerDomain}`}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {errors.appwebaddress && errors.appwebaddress.type === 'required' &&
                                                    <span className="error_cu">Web Address is required</span>}
                                                {errors.appwebaddress && errors.appwebaddress.type === 'pattern' &&
                                                    <span className="error_cu">Web Address is not valid</span>}
                                                {urlErr && <span className="error_cu">URL is not available</span>}
                                            </label>}

                                        </div>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="additional">

                                        <Container>
                                            <Row className="saveCompanyAditional">
                                                <Col md={6}>
                                                    <div>
                                                        <label>
                                                        <input type="text"
                                                            placeholder="Name"
                                                            name="first_name"
                                                            ref={register({ required: true })}
                                                            defaultValue={companyData.first_name}
                                                            // value={existName}
                                                            disabled={alreadyExist} 
                                                            />
                                                        {errors.first_name && errors.first_name.type === 'required' &&
                                                            <span className="error_cu">Name is required</span>}
                                                        </label>
                                                    </div>
                                                    <div>
                                                        {!company_id &&
                                                            <label>
                                                                 <input type="password"
                                                                    placeholder="Enter Password"
                                                                    name="password" ref={register({ required: true })}
                                                                    autocomplete = {true}
                                                                    defaultValue={alreadyExist === true ? "existpassword" : companyData.password || ""} 
                                                                    disabled={alreadyExist} />
                                                                {errors.password && errors.password.type === 'required' &&
                                                                    <span className="error_cu">Password is required</span>}
                                                            </label>}
                                                    </div>
                                                    <div>
                                                        <label className="contact">
                                                        <input type="number"
                                                            placeholder="Contact Number"
                                                            name="phone" ref={register({ required: true })}
                                                            defaultValue={alreadyExist === true ? existPhone : companyData.phone || ""} 
                                                            disabled={alreadyExist}
                                                            onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()} />
                                                        {errors.phone && errors.phone.type === 'required' &&
                                                            <span className="error_cu">Phone Number is required</span>} 
                                                           
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label className="input-email-label">
                                                        <input type="text"
                                                            placeholder="Login email id"
                                                            name="email"
                                                            ref={register({ required: true, pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/i })} defaultValue={companyData.email || ""} disabled={(company_id)} onChange={CheckEmailId} className="input-email"/>
                                                        {errors.email && errors.email.type === 'required' &&
                                                            <span className="error_cu">Email is required</span>}
                                                        {errors.email && errors.email.type === 'pattern' &&
                                                            <span className="error_cu">Please Enter Valid Email id</span>}
                                                        {emailErr == true &&
                                                            <span className="error_cu">These email is alredy exist to continue with these email<a href = "#" onClick={clickHandler}> click here </a> </span>}
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label>
                                                        <input type="text"
                                                            placeholder="Company copyright (Eg.© 2023 QaizenX ®. All Rights Reserved.)"
                                                            name="company_powered_by"
                                                            ref={register}
                                                            defaultValue={companyData?.company_powered_by}
                                                            />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label className="input-email-label">
                                                        <textarea rows="2" cols="50"
                                                            placeholder="Survey Footer Credits (Eg.Powered by Company name)"
                                                            name="SurveyFeedback_powered_by"
                                                            ref={register}
                                                            defaultValue={companyData?.SurveyFeedback_powered_by}
                                                            />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        {company_id ? companyData !== '' && <div className="d-flex">
                                                            <span className="expiry w-50 label">Company theme Color :</span>
                                                            <label className="select-year themeColor">
                                                            <input className="fld-value-input themecolor" name="theme_color" ref={register} defaultValue={companyData && (companyData?.theme_color || JSON.parse(localStore).current_partner_account.theme_color)} type="color" />
                                                            </label>
                                                        </div>:
                                                        <div className="d-flex">
                                                        <span className="expiry w-50 label">Company theme Color :</span>
                                                        <label className="select-year themeColor">
                                                        <input className="fld-value-input themecolor" name="theme_color" ref={register} defaultValue={ JSON.parse(localStore).current_partner_account.theme_color} type="color" />
                                                        </label>
                                                    </div>}
                                                    </div>
                                                </Col>
                                                <Col md={6} className="col1-scoreIndex">
                                                    <div>
                                                        <label className="datepicker-label">
                                                            <div className="subscription-start">
                                                                <span className="expiry w-50">Subscription Start Date :</span>
                                                                <label className="label-datepicker start-date">
                                                                    <DatePicker
                                                                        format={"dd/MM/yyyy"}
                                                                        selected={new Date(filterByDate.start_date)}
                                                                        value={new Date(filterByDate.start_date)}
                                                                        minDate={new Date(new Date(filterByDate.start_date).toDateString()).getTime() < new Date(new Date().toDateString()).getTime() ? false : new Date()}
                                                                        onChange={(date) => setFilterByDate({ ...filterByDate, start_date: new Date(date) })}
                                                                        clearIcon={null}
                                                                        className="sweet-datepicker-custom"
                                                                        disabled={new Date(new Date(filterByDate.start_date).toDateString()).getTime() < new Date(new Date().toDateString()).getTime() ? true : false}
                                                                    />
                                                                </label>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label className="datepicker-label">
                                                            <div className="subscription-end">
                                                                <span className="expiry endDate disabled-section w-50">Subscription End Date :</span>
                                                                <label className="label-datepicker disabled-section end-date">
                                                                    <DatePicker
                                                                        format={"dd/MM/yyyy"}
                                                                        // selected={new Date(newDate) || new Date() }
                                                                        value={new Date(newDate) || null}
                                                                        disabled={true}
                                                                        minDate={new Date(new Date(newDate).toDateString()).getTime() < new Date(new Date().toDateString()).getTime() ? false : new Date()}
                                                                        // onChange={(d) => setFilterByDate({ ...filterByDate, end_date: d })}
                                                                        clearIcon={null}
                                                                        className="sweet-datepicker-custom disabled-section"
                                                                    />
                                                                </label>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <div className="d-flex">
                                                            <span className="expiry w-50">Duration :</span>
                                                            <label className="select-year">
                                                                <Select
                                                                    ref={register}
                                                                    options={END_DATE}
                                                                    name='end-date'
                                                                    value={endDate}
                                                                    styles={outlineRemove}
                                                                    onChange={(selectedOption) => {
                                                                        setEndDate(selectedOption)
                                                                    }}
                                                                />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="d-flex img">
                                                            <span className="expiry w-50">Login page image :</span>
                                                            {(companyData?.login_page_img && choosenFile) ? <><img src={companyData?.login_page_img} alt="login-img" width="100px" height="100px" /><a className="a-link" onClick={showChoosenFile}>Update Image</a></> 
                                                                : <label className="select-year">
                                                                    <input type="file"
                                                                    placeholder="upload image"
                                                                    name="login_page_img"
                                                                    ref={register}
                                                                    accept="image/*"
                                                                    // defaultValue={companyData?.login_page_img} 
                                                                    className="border-0"
                                                                    />
                                                                    
                                                                </label>}
                                                        </div>
                                                    </div>
                                                    <div  className="d-flex">
                                                        <label class="email-check">
                                                            <div className="domian-adress-wrapper">

                                                                <div class="save-company-checkbox">
                                                                    <label class="ezi-checkbox checkbox-label">
                                                                        <input type="checkbox" name="send_email"
                                                                            ref={register}
                                                                            value={credentialEmail}
                                                                            checked={credentialEmail}
                                                                            onChange={({ target }) => setCredentialEmail(target.checked)} />
                                                                        <span class="ezi-checkbox-mark"></span></label>
                                                                    <span className="label-checkbox">Send Credentials Via Email</span>
                                                                </div>

                                                            </div>
                                                        </label>
                                                        <label class="email-check">
                                                            <div className="domian-adress-wrapper">

                                                                <div class="save-company-checkbox">
                                                                    <label class="ezi-checkbox checkbox-label">
                                                                        <input type="checkbox" name="tplButton"
                                                                            ref={register}
                                                                            value={ceoDashboard}
                                                                            checked={ceoDashboard}
                                                                            onChange={({ target }) => setCeoDashboard(target.checked)} />
                                                                        <span class="ezi-checkbox-mark"></span></label>
                                                                    <span className="label-checkbox">Enable CEO Dashboard</span>
                                                                </div>

                                                            </div>
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Count">
                                        <Container>
                                            <Row className="saveCompanyAditional">
                                                <Col md={12}>
                                                    <div className="security-card">
                                                        <div className="to-to-from">
                                                                <div className="startend-date-wrap">
                                                                    <label className="date-start">Start Date </label>
                                                                    <DatePicker 
                                                                        className="sweet-datepicker-custom" 
                                                                        selected={countDate.start_date || new Date()}
                                                                        value={countDate.start_date || new Date()}
                                                                        minDate={countDate.start_date  || new Date()}
                                                                        maxDate={countDate.end_date || new Date()}
                                                                        onChange ={(d) => setCountDate({ ...countDate, start_date: d })}
                                                                        />
                                                                </div>
                                                                <div className="startend-date-wrap">
                                                                    <label className="date-start ml-5">End Date </label>
                                                                    <DatePicker
                                                                        selected={countDate.end_date || new Date()}
                                                                        value={countDate.end_date || new Date()}
                                                                        minDate={countDate.start_date || new Date()}
                                                                        maxDate={new Date()} 
                                                                        onChange={(d) => setCountDate({ ...countDate, end_date: d })} 
                                                                        className="sweet-datepicker-custom" 
                                                                        />
                                                                </div>
                                                            <button type="button" onClick={communicationDetails} className="ezi-pink-btn btn-ripple go">Go</button>
                                                        </div> 
                                                        <div className="totalCount">
                                                            {jobDetails && jobDetails.map((item) => 
                                                                <div className="st_card">
                                                                    <div className="st_card-icon-wrap">
                                                                        { item.category === "Email" && <span className="st_card-icon template_ic"><AiTwotoneMail /></span>}
                                                                        { item.category === "WhatsApp" && <span className="st_card-icon template_ic"><BsWhatsapp /></span>}
                                                                        { item.category === "Sms" && <span className="st_card-icon template_ic"><BsFillChatLeftFill /></span>}
                                                                    </div>
                                                                    <div className="st_card-text-wrap">
                                                                        <span className="st_card-text">{item.category}</span>
                                                                        <span className="st_count">{item.total}</span>
                                                                    </div>
                                                                </div>
                                                            )} 
                                                            {openSurveyCount && openSurveyCount.map((item) => 
                                                                <div class="st_card">
                                                                    <div class="st_card-icon-wrap">
                                                                        { item.category === 'openSurvey' && <span class="st_card-icon template_ic"><AiOutlineQrcode /></span>}
                                                                    </div>
                                                                    <div class="st_card-text-wrap">
                                                                        <span class="st_card-text">{item.category && "QR"}</span>
                                                                        <span class="st_count">{item.total}</span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                      
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>

                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </form>
            </section>
        </React.Fragment>
    )
}
/* eslint-enable */
const mapStateToProps = state => {
    return {
        vendorInfo: state.app.vendorInfo
    }
}
export default connect(mapStateToProps)(SaveCompany);