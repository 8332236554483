import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import configURL from 'config/config';
import Axios from 'utility/Axios';
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import SelectSearch from "react-select-search";
import AppContext from 'store/AppContext';
import "../../assets/scss/SelectBox.scss"


const QuestionMapping = ({ onHide, mapQuestions, isShown }) => {
    let urlParams = useParams();
    const { languageObj = {} } = useContext(AppContext)
    const [loading, setLoading] = useState(false)
    const [questionMappings, setQuestionMappings] = useState({})
    const [childQuestions, setChildQuestions] = useState([])
    const [templateQuestions, setTemplateQuestions] = useState([])

    /**
     * Load Survey Parent and Child Question
     */
    const getMappingData = async () => {
        try {
            setLoading(true)
            let formData = new FormData()
            formData.append('template_id', urlParams.template_id)
            const mappingData = await Axios.post(configURL.getQuestionMapData, formData)
            if (mappingData.data.success) {
                let { child_questions = [], template_questions = [], mappings = {} } = mappingData.data
                setQuestionMappings(mappings)
                setChildQuestions(child_questions)
                setTemplateQuestions(template_questions)
            } else {
                toast.warn(mappingData.data.message || "Something is not right here..")
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.warn("Something is not right here..")
            console.error(error);
        }
    }

    useEffect(() => {
        if (isShown) {
            getMappingData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShown]);

    return (
        <Modal show={isShown} onHide={onHide} animation={true} className="ezi-right-animated-modal theme-mapping-right-modal" backdropClassName="ezi-right-modal-backdrop" >
            <Modal.Body>
                <section className="Page-ThemeMappingModal">
                    <div className="question-wrapper-section">
                        <div className="questions-header">
                            <span className="question-heading">Parent Questions Mappings</span>
                            <div className="question-save-wrap">
                                <button type="button" className="btn-ripple ezi-pink-btn question-save" onClick={() => {
                                    mapQuestions(questionMappings)
                                }}>{languageObj.translate('Save.1')}</button>
                                <button type="button" className="question-reset" onClick={() => setQuestionMappings({})}>{languageObj.translate('Reset.1')}</button>
                            </div>
                        </div>
                        <div className="que-mapping-body">
                            {
                                childQuestions.map((item) =>
                                    <div className="question-wrapper" key={item.id}>
                                        <p className="question-list">{item.name} </p>
                                        <div className="question-theme-select-wrap">
                                            <div className="react-select-search_c">
                                                <SelectSearch
                                                    options={templateQuestions}
                                                    search
                                                    value={questionMappings[item.id] ? questionMappings[item.id].toString() : null}
                                                    onChange={(value) => {
                                                        setQuestionMappings({
                                                            ...questionMappings,
                                                            [item.id]: value
                                                        })
                                                    }}
                                                    placeholder="Please Select Parent Question"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        {loading && <h5 style={{ textAlign: "center" }}>Loading Questions....</h5>}
                    </div>
                </section>
            </Modal.Body>
        </Modal>
    );
}

export default QuestionMapping